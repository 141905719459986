import React, { useMemo } from 'react'
import { Link, graphql, useStaticQuery} from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../components/Base/Layout'
import { PostsList } from '../components/Base/PagePostsList'
import { getSimplifiedPosts } from '../components/Utilities/helpers'

import looking from '../assets/images/me.png'

export default function WebsiteIndex() {

  const data = useStaticQuery(graphql`
    query IndexQuery {
      books: allMdx(
        limit: 8
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { categories: {in: ["technology"]} } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              tags
              categories
              published
            }
          }
        }
      }
      thoughts: allMdx(
        limit: 8
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { categories: {in: ["speculation"]} } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              tags
              categories
              published
            }
          }
        }
      }
      projects: allMdx(
        limit: 8
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { categories: { in: ["thoughts"] } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              tags
              published
            }
          }
        }
      }
      life: allMdx(
        limit: 8
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { categories: {in: ["life"]} } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              tags
              categories
              published
            }
          }
        }
      }
  }
  `)


  const books = data.books.edges
  const simpbook= useMemo(() => getSimplifiedPosts(books), [books])

  const thoughts = data.thoughts.edges
  const simplife= useMemo(() => getSimplifiedPosts(thoughts), [thoughts])

  const projects = data.projects.edges
  const simpproj= useMemo(() => getSimplifiedPosts(projects), [projects])

  const life = data.life.edges
  const simp_life= useMemo(() => getSimplifiedPosts(life), [life])

  return (
    <>
      <Layout>
      <Helmet title="Ten Years" />

      <article className="hero">
        <header>
          <div className="container">
            <div className="flex-content">
              <div>
                <p className="subtitle small">
                生活上频见生死，深夜痛哭；投资里曾万念俱灰，夜不能寐。<br/> 在不安与耐心的边缘徘徊，于贪婪和恐惧之间犹豫不前。人性修罗场缺席了数十年，临到而立，才开始逐字学。
                </p>

                <p className="subtitle small">
                思危、思退、思变。<br/>有志、有识、有恒。
                </p>

                <p className="subtitle small">
                愿旅途中有再次和你相见的缘分，和勇气。
                </p>

              </div>
              <img src={looking} alt="US" className="main-image-index" />
            </div>

          </div>
        </header>

        <div className="container">
          <h2 className="main-header">
            <span>Latest technology</span> <Link to="/technology">View all</Link>
          </h2>
          <PostsList data={simpbook} />
        </div>

        <div className="container">
          <h2 className="main-header">
            <span>Latest speculation</span> <Link to="/speculation">View all</Link>
          </h2>
          <PostsList data={simplife} />
        </div>

        <div className="container">
          <h2 className="main-header">
            <span>Latest thoughts</span> <Link to="/thoughts">View all</Link>
          </h2>
          <PostsList data={simpproj} />
        </div>

      </article>




      </Layout>
    </>
  )
}